<template>
  <section id="card-text-alignment">
    <section id="search-section">
      <b-row>
        <b-col md="12">
          <b-card>
            <Search></Search>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <section id="items">
      <b-row id="table-hover-row">
        <b-col md="12">

          <b-card>
            <b-row>
              <b-col md="2">
                Arama Bilgileri
              </b-col>

              <b-col md="2">
                Ürün Resim
              </b-col>

              <b-col md="4">
                Ürün Bilgileri
              </b-col>

              <b-col md="2">
                Stok Bilgileri
              </b-col>

              <b-col md="2">
                Yönetim
              </b-col>

            </b-row>
          </b-card>
          <Product v-for="item in orderBy(_products,'request.date',-1)" :key="item.date" :item="item"/>
        </b-col>
      </b-row>
    </section>
  </section>
</template>

<script>
import Search from "@/views/stock/stocktaking/Search";
import Product from "@/views/stock/stocktaking/Product"
import {mapGetters} from "vuex";
import Vue2Filters from 'vue2-filters'

export default {
  name: "StocktakingHome",
  mixins: [Vue2Filters.mixin],
  components: {Search, Product},
  computed: {
    ...mapGetters('stocktaking', ["_products"])
  }
}
</script>

<style scoped>

</style>
