<template>
  <b-card>
    <b-row>

      <b-col md="2">
        <div v-if="item.request">
          <span style="font-size: 1.5em;">Raf: {{ item.request.shelfCode }}</span><br>
          <span style="font-size: 1.5em;">Barkod: {{ item.request.barcode }}</span><br>
          <span style="font-size: 1.5em;">Adet: {{ item.request.quantity }}</span>
        </div>
      </b-col>
      <b-col md="2">
        <div v-if="item.product">
          <ProductImages :images="item.product.images" :prefix="'https://api.kitapfirsatlari.com/'"></ProductImages>
        </div>
      </b-col>
      <b-col md="4">
        <div v-if="item.product">
          <span style="font-size: 1.5em;">Ürün Adı: {{ item.product.name }}</span><br>
          <span style="font-size: 1.5em;">Barkod: {{ item.product.barcode }}</span><br>
          <span style="font-size: 1.5em;">Kod: {{ item.product.code }}</span>
        </div>
      </b-col>

      <b-col md="2">
        <div v-if="item.stock">
          <b-overlay
              :show="show"
              rounded="sm"
          >
            <span style="font-size: 1.5em;">Raf Adı: {{ item.stock.shelf_name }}</span><br>
            <span style="font-size: 1.5em;">Toplam Adet: {{ item.stock.quantity }}</span><br>
            <span style="font-size: 1.5em;">Fiyat: {{ item.stock.old_price }}</span>

            <b-form-group label="Ürün Adedi">
              <b-form-input type="number" size="xs" v-model="item.stock.quantity"/>
            </b-form-group>

            <b-form-group label="Raf Adı">
              <b-form-input type="text" size="xs" v-model="item.stock.shelf_name"/>
            </b-form-group>
            <b-button variant="success" @click="saveProduct">Düzelt Kaydet</b-button>
          </b-overlay>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ProductImages from "@/components/ProductImages";

export default {
  name: "Product",
  components: {ProductImages},
  data: () => ({
    show: false,
    editProduct: {
      quantity: null
    }
  }),
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    saveProduct() {
      this.show = true;
      this.$store.dispatch('stocktaking/updateProdcut', {
        id: this.item.stock.id,
        shelf_name: this.item.stock.shelf_name,
        quantity: this.item.stock.quantity,
        date: this.item.request.date,
      }).then(() => {
        this.show = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
