<template>
  <b-overlay
      :show="show"
      rounded="sm"
  >
    <b-form @submit.prevent>
      <b-row>

        <b-col md="2">
          <b-form-group label="Tedarikçi">
            <v-select
                v-model="addProduct.supplier_id"
                label="name"
                :options="_suppliers"
                :reduce="supplier => supplier.id"
                class="select-size-xs"
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group label="Raf Kodu">
            <b-form-input type="text" size="xs" v-model="addProduct.shelfCode"/>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Ürün Barcode">
            <b-form-input type="text" size="xs" v-model="addProduct.barcode" @keyup.enter="addNewProduct"/>
          </b-form-group>
          <span>lastBarcode: {{ lastBarcode }}</span>
        </b-col>

        <b-col md="1">
          <b-form-group label="Stok Adedi">
            <b-form-input type="number" size="xs" v-model="addProduct.quantity"/>
          </b-form-group>
        </b-col>

        <b-col md="1">
          <b-form-group label="Piyasa Fiyatı">
            <b-form-input
                type="number"
                size="xs"
                v-model="addProduct.old_market_price"
                @keyup.enter="calculate('market_price')"
                @blur="calculate('market_price')"
            />
          </b-form-group>
        </b-col>

        <b-col md="1">
          <b-form-group label="İskonto">
            <b-form-input
                type="number"
                size="xs"
                v-model="addProduct.old_discount"
                @keyup.enter="calculate('market_price')"
                @blur="calculate('market_price')"
            />
          </b-form-group>
        </b-col>

        <b-col md="1">
          <b-form-group label="Alış Fiyatı">
            <b-form-input
                type="number"
                size="xs"
                v-model="addProduct.old_price"
                @keyup.enter="calculate('price')"
                @blur="calculate('price')"
            />
          </b-form-group>
        </b-col>


      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {
  name: "Search",
  components: {vSelect},
  data: () => ({
    show: true,
    lastBarcode: "",
    addProduct: {
      supplier_id: "",
      shelfCode: "",
      barcode: "",
      quantity: 1,
      old_market_price: null,
      old_discount: null,
      old_price: null
    }
  }),
  computed: {
    ...mapGetters("supplier", ["_suppliers"])
  },
  methods: {
    addNewProduct() {
      this.show = true;
      if (this.addProduct.supplier_id != '' && this.addProduct.shelfCode != '' && this.addProduct.barcode != '' && this.addProduct.quantity > 0) {
        this.lastBarcode = this.addProduct.barcode;
        this.$store.dispatch('stocktaking/addNewProduct', {date: Date.now(), ...this.addProduct}).then(() => {
          this.addProduct.barcode = '';
          this.addProduct.quantity = 1;
          this.addProduct.supplier_id = "";
          this.addProduct.old_market_price = null;
          this.addProduct.old_discount = null;
          this.addProduct.old_price = null;
          this.show = false;
        })
      } else {
        alert('İçerik Boş olamaz')
        this.show = false;
      }
    },
    calculate(item) {
      if (item == 'market_price') {
        this.addProduct.old_price = this.addProduct.old_market_price - (this.addProduct.old_market_price * this.addProduct.old_discount / 100)
      } else if (item == 'price') {
        this.addProduct.old_discount = (this.addProduct.old_market_price - this.addProduct.old_price) / this.addProduct.old_market_price * 100;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
